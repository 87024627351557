<template>
  <div class="setting-page">
    <vuestic-widget>
      <template slot="header">
        <span class="title">Settings</span>
      </template>
      <div>
        <b-tabs small card>
          <b-tab title="Information" :active="tabIndex == 1">
            <Information />
          </b-tab>
          <b-tab title="Notification" :active="tabIndex == 2">
            <Notification />
          </b-tab>
        </b-tabs>
      </div>
    </vuestic-widget>
  </div>
</template>
<script>

import Information from "./Information";
import Notification from "./Notification";

export default {
  name: "business-settings",
  components: {
    Information, Notification
  },
  data() {
    return {
      tabIndex: 1,
    };
  },
  mounted() {
    if (this.$route.query.tab)
      this.tabIndex = this.$route.query.tab
  },
  computed: {
    user() {
      return this.$store.state.auth.user;
    },
  },
  methods: {
  },
};
</script>
<style lang="scss">
.setting-page {
  .widget-body {
    padding: 0;
  }
}
</style>