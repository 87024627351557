<template>
  <div class="agency-notification-wrapper">
    <ValidationObserver v-slot="{ handleSubmit }" ref="notificationForm">
      <form @submit.prevent="handleSubmit(onUpdate)">
        <div class="row">
          <div class="col-md-12 mt-4">
            <label class="input-label has-tool-tip my-2">90% of call limit  <a v-b-tooltip.hover
                  title="This reply will be sent out to businesses when they reach 90% of call limit."
                  class="fa fa-question-circle tooltip-icon" /></label>
            <vuestic-switch v-model="settings.call_notification_90_enabled" :offcolor=true style="max-width: 200px; margin-bottom: 20px;">
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
            <div v-if="settings.call_notification_90_enabled" class="d-block">
              <TextareaEmojiPicker v-model="settings.call_notification_90_subject" :focused="true" :rows="2"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Subject" />
              <br/>
              <TextareaEmojiPicker v-model="settings.call_notification_90_content" :focused="true" :rows="3"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Content" />
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <label class="input-label has-tool-tip my-2">call limit  <a v-b-tooltip.hover
                  title="This reply will be sent out to businesses when they reach call limit."
                  class="fa fa-question-circle tooltip-icon" /></label>
            <vuestic-switch v-model="settings.call_notification_enabled" :offcolor=true style="max-width: 200px; margin-bottom: 20px;">
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
            <div v-if="settings.call_notification_enabled" class="d-block">
              <TextareaEmojiPicker v-model="settings.call_notification_subject" :focused="true" :rows="2"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Subject" />
              <br/>
              <TextareaEmojiPicker v-model="settings.call_notification_content" :focused="true" :rows="3"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Content" />
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <label class="input-label has-tool-tip my-2">90% of sms limit  <a v-b-tooltip.hover
                  title="This reply will be sent out to businesses when they reach 90% of sms limit."
                  class="fa fa-question-circle tooltip-icon" /></label>
            <vuestic-switch v-model="settings.sms_notification_90_enabled" :offcolor=true style="max-width: 200px; margin-bottom: 20px;">
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
            <div v-if="settings.sms_notification_90_enabled" class="d-block">
              <TextareaEmojiPicker v-model="settings.sms_notification_90_subject" :focused="true" :rows="2"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Subject" />
              <br/>
              <TextareaEmojiPicker v-model="settings.sms_notification_90_content" :focused="true" :rows="3"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Content" />
            </div>
          </div>
          <div class="col-md-12 mt-4">
            <label class="input-label has-tool-tip my-2">sms limit  <a v-b-tooltip.hover
                  title="This reply will be sent out to businesses when they reach sms limit."
                  class="fa fa-question-circle tooltip-icon" /></label>
            <vuestic-switch v-model="settings.sms_notification_enabled" :offcolor=true style="max-width: 200px; margin-bottom: 20px;">
              <span slot="trueTitle">ON</span>
              <span slot="falseTitle">OFF</span>
            </vuestic-switch>
            <div v-if="settings.sms_notification_enabled" class="d-block">
              <TextareaEmojiPicker v-model="settings.sms_notification_subject" :focused="true" :rows="2"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Subject" />
              <br/>
              <TextareaEmojiPicker v-model="settings.sms_notification_content" :focused="true" :rows="3"
                ref="textareaEmojiAutoReply" :showSendButton="false" :hasHoverEffect="false"
                :enabledMergeField="false" label="Content" />
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
    <div class="row">
      <div class="col-md-12 mt-3">
        <button @click="onUpdate" :disabled="processing" class="btn btn-primary d-block ml-auto mr-auto">
          <atom-spinner v-if="processing" slot="loading" :animation-duration="1500" :size="14" color="#FFF" />
          <span v-else>Update</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import TextareaEmojiPicker from "../../common/TextareaEmojiPicker";

export default {
  name: "agency-notification",
  components: {
    TextareaEmojiPicker
  },

  data() {
    return {
      loading: false,
      processing: false,
      settings: {},
    };
  },

  created() {
    this.initData()
  },
  mounted() {
    
  },
  computed: {
    user() {
      return this.$store.getters['auth/user']
    },
    agency() {
      return this.user && this.user.agency
    },
  },

  methods: {
    initData() {
      this.settings = this.agency.settings
    },
    onUpdate() {
      this.$refs.notificationForm.validate().then(valid => {
        if (valid) {
          this.processing = true;
          this.$store.dispatch('agency/updateSettingsNew', this.settings)
            .then((res) => {
              this.settings = {
                ...res.settings
              }
              this.processing = false;
            })
            .catch(errors => {
              this.processing = false;
              if (errors.response.data) {
                this.$nextTick(() => {
                  this.$refs.notificationForm.setErrors(errors.response.data.errors)
                })
              }
            })
        } else {
          this.scrollToError(this.$refs.notificationForm)
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.agency-notification-wrapper {
  max-width: 768px;
}
</style>
